<template>
  <div class="btsb">
    <el-button
      style="position: absolute;top：0; right: 10px"
      type="primary"
      icon="el-icon-arrow-left"
      @click="goback"
      >返回</el-button
    >
    <h1>{{ title }}</h1>
    <div v-show="activeName == 2&&type==''">
      申请人：
      <el-input
        style="width: 160px"
        placeholder="请输入内容"
        v-model="input"
        clearable
      >
      </el-input>

      <el-button style="margin-left: 30px" type="primary" @click="search"
        >查询</el-button
      >
    </div>
    <!-- <div style="color: #139959; font-weight: bold; margin-top: 20px">
      补贴总户数:126户（单位） <span>总面积:8000亩</span>
    </div> -->
    <table class="btsb-table">
      <tr>
        <td>村(社区)</td>
        <td>补贴总面积(亩)</td>
        <td>补贴普通农户(家庭农场)数</td>
        <td>补贴普通农户(家庭农场)面积(亩)</td>
        <td>补贴专业合作社(农业企业)数</td>
        <td>补贴专业合作社(农业企业)面积(亩)</td>
      </tr>
      <tr>
        <td>{{ tableData.length > 0 ? tableData[0].areaName : "" }}</td>
        <td>{{ normalArea + specialArea }}</td>
        <td>{{ normalFarmers }}</td>
        <td>{{ normalArea }}</td>
        <td>{{ specialFarmers }}</td>
        <td>{{ specialArea }}</td>
      </tr>
    </table>
    <el-tabs
      type="card"
      :stretch="true"
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane label="明细表1（农户、家庭农场）" name="1">
        <div class="scroll-container">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="areaName" label="村（社区）" align="center">
            </el-table-column>
            <el-table-column prop="group" label="组" align="center">
            </el-table-column>
            <el-table-column prop="declareName" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="idCard" label="身份证号" align="center">
            </el-table-column>
            <el-table-column
              prop="familyPerson"
              label="家庭（单位）受益人数（人）"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="plantingSubsidyArea"
              label="实际种植补贴面积（亩）"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="contractArea"
              label="其中承包面积（亩）"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="phone" label="电话号码" align="center">
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="明细表2
(专业合作社、农业企业)"
        name="2"
      >
        <div class="scroll-container">
          <el-table :data="tableData1" border style="width: 100%">
            <el-table-column prop="areaName" label="村（社区）" align="center">
            </el-table-column>
            <el-table-column prop="companyName" label="企业名称" align="center">
            </el-table-column>
            <el-table-column
              prop="legalIdCard"
              label="企业法人身份证号"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="legalAccountName"
              label="企业法人名称"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="legalName"
              label="企业法人开户名"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="legalBankName"
              label="企业法人开户行"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="legalBankCard"
              label="企业法人对公账户"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="enjoySubsidies"
              label="企业下享受补贴人数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="contractArea"
              label="承包种植面积（亩）"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="cultivationArea"
              label="代耕代种面积（亩）"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="landArea"
              label="实际种植补贴面积（亩）"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="phone" label="联系电话" align="center">
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 相关材料 -->
    <div style="display: flex; text-align: center; margin-top: 40px">
      <div style="margin-right: 30px; font-weight: bold">相关材料:</div>
      <div>
        <el-image
          style="width: 100px; height: 100px; margin-right: 10px"
          v-for="(item, index) in otherFiles"
          :key="index"
          :src="baseUrl + 'admin/file/get?ossFilePath=' + item.imgUrl"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueQr from "vue-qr";
import { log } from "console";
const baseUrl = window.globalUrl.HOME_API;
const Url = window.globalUrl.api1;
import { selectFoodPriceList, selectFoodPriceDetail } from "@/api/snbt";
import { title } from "process";
import { get } from "lodash";
import { getMySubsidyById } from "@/api/subsidy";
export default {
  name: "补贴申报",
  components: {
    VueQr,
  },
  mixins: [],
  props: {},
  data() {
    return {
      // 补贴普通农户(家庭农场)数
      normalFarmers: 0,
      // 补贴普通农户(家庭农场)面积(亩)
      normalArea: 0,
      // 补贴专业合作社(农业企业)数
      specialFarmers: 0,
      // 补贴专业合作社(农业企业)面积(亩)
      specialArea: 0,
      title: "", // 页面标题
      subsidyId: "", // 补贴ID
      baseUrl: baseUrl, // 接口地址
      tableData: [], // 表格数据（明细表1）
      tableData1: [], // 表格数据（明细表2）
      otherFiles: [], // 其他材料
      activeName: "1", // 激活的选项卡
      status: "", // 状态
      taskKey: "", // 任务key
      input: "", // 申请人
      type: "", // 类型
    };
  },
  created() {
    this.title = this.$route.query.title;
    this.subsidyId = this.$route.query.subsidyId;
    this.status = this.$route.query.status;
    this.taskKey = this.$route.query.taskKey;
    this.type = this.$route.query.type;
    // this.getFoodPriceList();
    if(this.$route.query.type=='ck'){
      this.getlist();
    }else{
         this.gettableData();
    }
 
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  watch: {},
  mounted() {},
  methods: {
    async getlist() {
      let res = await getMySubsidyById({ id: this.subsidyId });
      console.log(res);
      if (res.code == 0) {
        this.otherFiles = JSON.parse(res.data.otherFiles)
          ? JSON.parse(res.data.otherFiles)
          : [];
        this.tableData = res.data.twActivitySubsidyFoodPriceFarmersList
          ? res.data.twActivitySubsidyFoodPriceFarmersList
          : [];
        this.tableData1 = res.data
          .twActivitySubsidyFoodPriceCooperativeList
          ? res.data.twActivitySubsidyFoodPriceCooperativeList
          : [];
      }
      this.usnumber();
    },
    //  获取粮食价格列表查询
    // async getFoodPriceList() {
    //   const res = await selectFoodPriceList();
    //   this.list = res.data.data;
    //   console.log(res.data.data);
    // },
    //  获取粮食明细表
    async gettableData() {
      const res = await selectFoodPriceDetail({
        declareName: this.input,
        areaCode: "",
        status: this.status,
        taskKey: this.taskKey,
        subsidyId: this.subsidyId,
      });
      if (res.code == 0) {
        this.otherFiles = JSON.parse(res.data.data.otherFiles)
          ? JSON.parse(res.data.data.otherFiles)
          : [];
        this.tableData = res.data.data.twActivitySubsidyFoodPriceFarmersList
          ? res.data.data.twActivitySubsidyFoodPriceFarmersList
          : [];
        this.tableData1 = res.data.data
          .twActivitySubsidyFoodPriceCooperativeList
          ? res.data.data.twActivitySubsidyFoodPriceCooperativeList
          : [];
      }
      this.usnumber();
      console.log(this.otherFiles);
    },
    // 计算合
    usnumber() {
      this.specialFarmers = 0;
      this.specialArea = 0;
      this.normalFarmers = 0;
      this.normalArea = 0;
      this.specialFarmers = this.tableData1
        .map((item) => {
          return Number(item.enjoySubsidies);
        })
        .reduce((total, current) => {
          return total + current;
        }, this.specialFarmers);

      this.specialArea = this.tableData1
        .map((item) => {
          return Number(item.landArea);
        })
        .reduce((total, current) => {
          return total + current;
        }, this.specialArea);

      this.normalArea = this.tableData
        .map((item) => {
          return Number(item.plantingSubsidyArea);
        })
        .reduce((total, current) => {
          return total + current;
        }, this.normalArea);

      this.normalFarmers = this.tableData
        .map((item) => {
          return Number(item.familyPerson);
        })
        .reduce((total, current) => {
          return total + current;
        }, this.normalFarmers);
    },
    // 查询
    search() {
      console.log(this.input);
      this.gettableData();
    },
    // 选项卡切换
    handleClick(tab, event) {
      this.activeName = tab.name;
      //   console.log(tab.name, event);
    },

    // 返回上一级
    goback() {
      this.$router.push({
        path: "/memberCenter/snbt/snbtbl",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tongyi {
  box-sizing: border-box;
  .topbox {
    span {
      width: 30%;
      font-size: 20px;
      // font-weight: 400;
      color: #000;
    }
    input {
      width: 60%;
      font-size: 20px;
    }
  }

  .footerbox {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .img {
      width: 100%;
      height: 100%;
    }
  }
}
.btsb {
  padding: 30px;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .btsb-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    td {
      border: 1px solid #ccc;
      padding: 10px;
      text-align: center;
    }
  }

  .tongyi {
    box-sizing: border-box;
    .topbox {
      span {
        width: 30%;
        font-size: 20px;
        // font-weight: 400;
        color: #000;
      }
      input {
        width: 60%;
        font-size: 20px;
      }
    }

    .footerbox {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      .img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.error-cell {
  // background-color: lightcoral;
  border-bottom: 3px solid red !important;
}

.scroll-container {
  overflow-x: auto; /* 启用横向滚动 */
  white-space: nowrap; /* 防止内容换行 */
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    thead {
      td {
        font-weight: bold;
        white-space: normal; /* 正常换行 */
      }
    }
    td {
      border: 1px solid #ccc;
      padding: 3px;
      text-align: center;
    }
  }
  input {
    border: none; /* 移除边框 */
    font-size: 16px; /* 设置字体大小为默认大小 */
    // padding: 4px; /* 设置内边距 */
    outline: none; /* 移除选中时的边框 */
    text-align: center;
  }
}
</style>
